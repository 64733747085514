import clsx from 'clsx';
import { Box, Button, Drop, Text } from 'grommet';
import { Add, FormClose } from 'grommet-icons';
import { useRef, useState } from 'preact/hooks';
import { useDispatch } from 'react-redux';
import { API_BASE_URL } from 'Shared/fetch';
import useFetch from 'use-http';

import style from './style.scss';

const TAGS_ENUM = Object.freeze([
  'Moving',
  'Eating',
  'Cute Alert',
  'Enrichment',
  'Playing',
  'Breeding',
  'Grooming',
  'Parenting',
  'Resting',
]);

/**
 * Renders a component for tagging content.
 *
 * @param {Object} props - The component props.
 * @param {string} [props.userId] - The user ID.
 * @param {Array} [props.allTags=[]] - The array of all available tags.
 * @param {string} props.type - The type of content being tagged.
 * @param {string} props.mediaId - The ID of the content being tagged.
 * @param {import('@reduxjs/toolkit').ActionCreatorWithPayload<{ contentId: string, tag: string, userId: string }>|function(string)} props.addTagToContent - The function to add a tag to the content.
 * @param {import('@reduxjs/toolkit').ActionCreatorWithPayload<{ contentId: string, tag: string, userId: string }>|function(string)} props.removeTagFromContent - The function to remove a tag from the content.
 * @param {boolean} [props.updateTags=true] - A flag to determine whether to update tags on the API side. Optional, defaults to true.
 * @return {import('preact').JSX.Element} The rendered Tagging component.
 */
const Tagging = ({
  userId,
  allTags = [],
  type,
  mediaId: contentId,
  addTagToContent,
  removeTagFromContent,
  updateTags = true,
}) => {
  const [tags, setTags] = useState(allTags);
  const [open, setOpen] = useState(false);
  const selectRef = useRef();
  const dispatch = useDispatch();

  const { put } = useFetch(API_BASE_URL, { credentials: 'include', cachePolicy: 'no-cache ' });

  const apiTagsUpdate = async (newTags, dispatchFunction) => {
    try {
      await put(`/${type}/${contentId}/tags`, { tags: newTags });
      setTags(newTags);
      dispatch(dispatchFunction);
    } catch (error) {
      console.error(error);
    }
  };

  const onAddTag = async (tag) => {
    if (!tags.some((t) => t.tag === tag && t.users.includes(userId))) {
      const existingTag = tags.find((t) => t.tag === tag);
      let newTags;
      if (existingTag) {
        newTags = tags.map((t) => (t.tag === tag ? { ...t, users: [...t.users, userId] } : t));
      } else {
        newTags = [...tags, { tag, users: [userId] }];
      }
      if (updateTags) {
        await apiTagsUpdate(newTags, addTagToContent({ contentId, tag, userId }));
      } else {
        addTagToContent(tag);
        setTags(newTags);
      }
    }
  };

  const onRemoveTag = async (tag) => {
    const newTags = tags.map((t) => (t.tag === tag ? { ...t, users: t.users.filter((id) => id !== userId) } : t));
    if (updateTags) {
      await apiTagsUpdate(newTags, removeTagFromContent({ contentId, tag, userId }));
    } else {
      removeTagFromContent(tag);
      setTags(newTags);
    }
  };

  const availableTags = TAGS_ENUM.filter((tag) => !tags.some((t) => t.tag === tag && t.users?.includes(userId)));

  return (
    <Box className={style.tagContainer}>
      {tags
        .filter(({ users }) => users?.length)
        .map(
          ({ tag, users }) =>
            tag && (
              <Box className={style.taggingBox} key={tag}>
                {users.includes(userId) && (
                  <Button
                    icon={<FormClose size="14px" color="var(--primaryGreen)" />}
                    onClick={() => onRemoveTag(tag)}
                    plain
                  />
                )}
                <Text className={style.tagText}>
                  {tag}
                  {users.length > 1 && ` (${users.length})`}
                </Text>
              </Box>
            ),
        )}

      {availableTags.length > 0 && (
        <Box ref={selectRef} onClick={() => setOpen(!open)} className={clsx([style.taggingBox, style.greenBox])}>
          <Add size="10px" color="var(--white)" />
          <Text className={clsx([style.tagText, style.whiteText])}>Add Label</Text>
        </Box>
      )}

      {open && availableTags.length > 0 && selectRef.current && (
        <Drop
          target={selectRef.current}
          align={{ top: 'bottom', left: 'left' }}
          onClickOutside={() => setOpen(false)}
          className={style.dropBox}
        >
          {availableTags.map((tag) => (
            <Box key={tag} onClick={() => onAddTag(tag)} className={clsx([style.taggingBox, style.whiteBox])}>
              <Text className={style.tagText}>{tag}</Text>
            </Box>
          ))}
        </Drop>
      )}
    </Box>
  );
};

export default Tagging;
